import Pledge, { EPledgeBackingPlatform } from '../../../models/treasury/pledges/Pledge';
import { ActionIcon, Box, Button, Flex, Stack, Table, Tooltip } from '@mantine/core';
import { useEffect } from 'react';
import { MdOutlineDelete, MdOutlineEdit, MdOutlineWarning } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import { ReactComponent as BackerKit } from '../../../assets/backerkit.svg';
import { ReactComponent as Shopify } from '../../../assets/shopify.svg';

export default function PledgeSection({
    selectedEmail,
    pledges,
    deletePledge,
    editPledge,
    viewPledge
}: {
    selectedEmail: string;
    pledges: Pledge[];
    deletePledge: (id: number) => void;
    editPledge: (pledge: Pledge) => void;
    viewPledge: (pledge: Pledge) => void;
}) {
    useEffect(() => {}, [pledges]);

    return (
        <Box>
            <Stack>
                <h3>Pledges</h3>
                <Table highlightOnHover sx={{ width: '96%' }}>
                    <thead>
                        <tr>
                            <th>Reward</th>
                            <th>Status</th>
                            <th>Available</th>
                            <th>Claimed</th>
                            <th>Backer Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pledges.map((pledge) => (
                            <PledgeItem
                                key={pledge.pledge_id}
                                editPledge={editPledge}
                                pledge={pledge}
                                deletePledge={deletePledge}
                                viewPledge={viewPledge}
                            />
                        ))}
                    </tbody>
                </Table>
            </Stack>
        </Box>
    );
}

export function PledgePlatform({ pledge }: { pledge: Pledge }) {
    const iconStyle = { width: '18px', height: '18px', marginLeft: '5px' };

    if (pledge.backing_platform === EPledgeBackingPlatform.BACKERKIT) {
        return (
            <Tooltip label="Reward Imported from Backer Kit">
                <BackerKit style={iconStyle} />
            </Tooltip>
        );
    } else if (pledge.backing_platform === EPledgeBackingPlatform.SHOPIFY) {
        return (
            <Tooltip label="Shopify Preorder">
                <Shopify style={iconStyle} />
            </Tooltip>
        );
    }
    return <></>;
}

export function PledgeItem({
    pledge,
    deletePledge,
    editPledge,
    viewPledge,
}: {
    editPledge: (pledge: Pledge) => void;
    viewPledge: (pledge: Pledge) => void;
    pledge: Pledge;
    deletePledge: (id: number) => void;
}) {
    const getStatusText = (status: number) => {
        switch (status) {
            case 1:
                return 'Development';
            case 2:
                return 'Available';
            case 3:
                return 'Fulfilled';
        }
    };

    return (
        <tr style={{ maxHeight: '40px', height: '40px' }}>
            <td width={'30%'} height={'40px'}>
                <Flex dir="row" align="center">
                    <TextWithMaxLength maxLength={30}>{pledge.reward?.reward_name ?? ''}</TextWithMaxLength>
                    <PledgePlatform pledge={pledge} />
                    {!pledge.imported && (
                        <Tooltip label="Not Imported">
                            <ActionIcon>
                                <MdOutlineWarning color="orange" />
                            </ActionIcon>
                        </Tooltip>
                    )}
                </Flex>
            </td>
            <td width={'80px'} height={'40px'}>
                {getStatusText(pledge.reward?.reward_status ?? 1)}
            </td>
            <td height={'40px'}>{pledge.available_quantity}</td>
            <td height={'40px'}>{pledge.claimed_quantity}</td>
            <td height={'40px'}>
                <TextWithMaxLength>{pledge.backer_email}</TextWithMaxLength>
            </td>
            <td height={'40px'}>
                <Flex dir="row" columnGap={'xs'}>
                    <Tooltip label="Only Available For Shopify Items">
                        <Button
                            disabled={pledge.backing_platform !== EPledgeBackingPlatform.SHOPIFY}
                            onClick={(e) => {
                                e.preventDefault();
                                if (!!viewPledge) viewPledge(pledge);
                            }}
                        >
                            <FaEye />
                        </Button>
                    </Tooltip>

                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            if (!!editPledge) editPledge(pledge);
                        }}
                        color="orange"
                    >
                        <MdOutlineEdit />
                    </Button>

                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            if (!!deletePledge) deletePledge(pledge.pledge_id);
                        }}
                        color="red"
                    >
                        <MdOutlineDelete />
                    </Button>
                </Flex>
            </td>
        </tr>
    );
}

export function TextWithMaxLength({ children, maxLength }: { children: string; maxLength?: number }) {
    const unwrappedMaxLength = !!maxLength ? maxLength : 30;

    if (children.length > unwrappedMaxLength) {
        return (
            <Tooltip label={children}>
                <p>{children.substring(0, unwrappedMaxLength)}...</p>
            </Tooltip>
        );
    }

    return <p>{children}</p>;
}
