import { Reward } from "../rewards/Reward";

export default interface Pledge
{
    pledge_id  : number;
    wod_email : string;
    backer_email: string;
    reward_id : number;
    quantity : number;
    available_quantity : number;
    claimed_quantity : number;
    reward? : Reward,
    imported: boolean,
    backing_platform : EPledgeBackingPlatform;
    shopify_data : PledgeShopifyData | null;
}

export enum EPledgeBackingPlatform{
    BACKERKIT = 1,
    SHOPIFY = 2
}

export interface PledgeShopifyData{
    pledge_id : number;
    order_id : number;
    date : Date;
}

