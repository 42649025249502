import Nullable from '@/dataTypes/Nullable';
import Pledge, { EPledgeBackingPlatform, PledgeShopifyData } from '../../../models/treasury/pledges/Pledge';
import { Button, Checkbox, Modal, NumberInput, Select, Stack, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { ShopifyDataView } from '../pledge-add-backer/pledge-add-backer.component';

export interface PledgeEditFormProps {
    visible: boolean;
    closed: () => void;
    pledge: Pledge;
    edit: (
        pledge: Pledge,
        backerKitEmail: string,
        backingPlatform: EPledgeBackingPlatform,
        claimedQuantity: number,
        quantity: number,
        imported: boolean,
        shopifyData?: Nullable<PledgeShopifyData>,
    ) => void;
}

export default function PledgeEditForm({ visible, closed, pledge, edit }: PledgeEditFormProps) {
    useEffect(() => {
        if (!!pledge) {
            setBackerEmail(pledge.backer_email);
            setQuantity(pledge.quantity);
            setClaimedQuantity(pledge.claimed_quantity);
            setImported(pledge.imported);
            setShopifyData(pledge.shopify_data);
            setBackingPlatform(pledge.backing_platform);
        }
    }, [pledge]);

    const [backerEmail, setBackerEmail] = useState(pledge?.backer_email ?? '');
    const [quantity, setQuantity] = useState(pledge?.quantity ?? 0);
    const [claimedQuantity, setClaimedQuantity] = useState(pledge?.claimed_quantity ?? 0);
    const [imported, setImported] = useState(pledge?.imported ?? false);
    const [shopifyData, setShopifyData] = useState<Nullable<PledgeShopifyData>>(null);
    const [backingPlatform, setBackingPlatform] = useState<EPledgeBackingPlatform>(EPledgeBackingPlatform.BACKERKIT);

    const backingPlatformData = [
        { label: 'BackerKit', value: EPledgeBackingPlatform.BACKERKIT.toString() },
        { label: 'Shopify', value: EPledgeBackingPlatform.SHOPIFY.toString() },
    ];

    const editHandler = () => {
        edit(pledge, backerEmail, backingPlatform, claimedQuantity, quantity, imported, shopifyData);
    };

    return (
        <Modal opened={visible} onClose={closed} centered title="Edit Pledge">
            <Stack>
                <TextInput label="Reward" value={pledge?.reward?.reward_name ?? 'Not Available'} readOnly disabled />
                <TextInput
                    label="Backer Email"
                    value={backerEmail}
                    onChange={(e) => {
                        e.preventDefault();
                        setBackerEmail(e.target.value);
                    }}
                />
                <Select
                    value={backingPlatform.toString()}
                    data={backingPlatformData}
                    label="Backing Platform"
                    onChange={(e) => {
                        if (e !== null) {
                            const targetBackingPlatform = parseInt(e);
                            setBackingPlatform(targetBackingPlatform);

                            if (targetBackingPlatform === EPledgeBackingPlatform.SHOPIFY) {
                                setShopifyData({
                                    pledge_id: 0,
                                    order_id: 0,
                                    date: new Date(),
                                });
                            } else {
                                setShopifyData(null);
                            }
                        }
                    }}
                />
                <NumberInput
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => {
                        if (!!e) setQuantity(e);
                    }}
                />
                <NumberInput
                    label="Claimed Quantity"
                    value={claimedQuantity}
                    onChange={(e) => {
                        if (!!e) setClaimedQuantity(e);
                    }}
                />
                <Checkbox
                    checked={imported}
                    onChange={(e) => {
                        setImported(e.target.checked);
                    }}
                    label="Imported"
                />
                {backingPlatform === EPledgeBackingPlatform.SHOPIFY && !!shopifyData && (
                    <ShopifyDataView shopifyData={shopifyData} setShopifyData={setShopifyData} />
                )}
                <Button onClick={editHandler}>Save Pledge</Button>
            </Stack>
        </Modal>
    );
}
