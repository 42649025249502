import Pledge from '../../../models/treasury/pledges/Pledge';
import { Button, Modal, NumberInput, Stack, TextInput } from '@mantine/core';

export interface PledgeViewDetailsFormProps {
    visible: boolean;
    closed: () => void;
    pledge: Pledge;
}

export default function PledgeViewDetails({ visible, closed, pledge }: PledgeViewDetailsFormProps) {
    console.log('PledgeViewDetails', pledge);
    if (!pledge?.shopify_data) {
        return <></>;
    }


    const openShopifyOrder = () => {
        if (pledge.shopify_data?.order_id) {
            const shopifyOrderUrl = `https://kingdomdeath.myshopify.com/admin/orders/${pledge.shopify_data.order_id}`;
            window.open(shopifyOrderUrl, '_blank');
        }
    };

    console.log('PledgeViewDetails Visible', visible);

    return (
        <Modal opened={visible} onClose={closed} centered title="View Pledge">
            <Stack>
                <TextInput label="Reward" value={pledge?.reward?.reward_name ?? 'Not Available'} readOnly />
                <TextInput label="Backer Email" value={pledge?.backer_email ?? ''} readOnly />
                <NumberInput label="Shopify Order ID" value={pledge.shopify_data!.order_id} readOnly />
                <TextInput label="Shopify Order Date" value={pledge.shopify_data!.date.toString()} readOnly />
                <Button onClick={openShopifyOrder} color="blue">
                    Open Shopify Order
                </Button>

            </Stack>
        </Modal>
    );
}
