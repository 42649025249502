import Nullable from '@/dataTypes/Nullable';
import { EPledgeBackingPlatform, PledgeShopifyData } from '../../../models/treasury/pledges/Pledge';
import ITreasuryService from '@/services/ITreasuryService';
import { Button, Checkbox, Modal, NumberInput, Select, Stack, TextInput, LoadingOverlay, Alert, Header } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { DateInput } from '@mantine/dates';
import ShopifyProductData from '@/models/trans/storeProducts/Shopify/ShopifyProductData';

export interface PledgeAddBackerProps {
    visible: boolean;
    closed: () => void;
    email: string | null;
    create: (rewardId: number, email: string, backerEmail: string, backingPlatform: EPledgeBackingPlatform, quantity: number, imported: boolean, shopifyData: Nullable<PledgeShopifyData>) => void;
}

export default function PledgeAddBacker({ visible, closed, create, ...rest }: PledgeAddBackerProps) {
    const [selectData, setSelectData] = useState<{ label: string; value: string }[]>([]);
    const [selectValue, setSelectValue] = useState<Nullable<string>>(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(rest.email ?? '');
    const [backingPlatform, setBackingPlatform] = useState<EPledgeBackingPlatform>(EPledgeBackingPlatform.BACKERKIT);
    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');

    const backingPlatformData = [
        { label: 'BackerKit', value: EPledgeBackingPlatform.BACKERKIT.toString() },
        { label: 'Shopify', value: EPledgeBackingPlatform.SHOPIFY.toString() },
    ];

    useEffect(() => {
        if (!!visible) {
            setLoading(true);

            treasuryService.getAllRewards().then((x) => {
                const tempSelectData = x
                    ?.getItems()
                    .map((it) => ({ label: it.reward_name, value: it.reward_id.toString() }));
                setSelectData(tempSelectData ?? []);
                setLoading(false);
            });
        }
    }, [visible]);

    const [backerEmail, setBackerEmail] = useState(email);
    const [customEmail, setCustomEmail] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [imported, setImported] = useState(true);
    const [shopifyData, setShopifyData] = useState<Nullable<PledgeShopifyData>>(null);

    useEffect(() => {
        if (!customEmail) setBackerEmail(email);
    }, [email, customEmail]);

    useEffect(() => {
        setEmail(rest.email ?? '');
    }, [rest.email]);

    const createHandler = () => {
        if (!selectValue) {
            closed();
            return;
        }
        const rewardValue = parseInt(selectValue);
        if (Number.isNaN(rewardValue)) {
            closed();
            return;
        }
        create(rewardValue, email, backerEmail, backingPlatform, quantity, imported, shopifyData);
    };

    const titleText = (() => {
        if (rest.email !== null) return 'Add Pledge';
        else return 'Add Backer and Pledge';
    })();

    return (
        <Modal opened={visible} onClose={closed} centered title={titleText}>
            <Stack>
                <TextInput
                    disabled={rest.email !== null}
                    readOnly={rest.email !== null}
                    label="Backer AoD Email"
                    value={email}
                    onChange={(e) => {
                        e.preventDefault();
                        setEmail(e.target.value);
                    }}
                />
                <Checkbox
                    checked={customEmail}
                    onChange={(e) => {
                        setCustomEmail(e.target.checked);
                    }}
                    label="Custom Backer Email"
                />
                <TextInput
                    label="Backer Email"
                    value={backerEmail}
                    disabled={!customEmail}
                    onChange={(e) => {
                        e.preventDefault();
                        setBackerEmail(e.target.value);
                    }}
                />
                <Select
                    value={backingPlatform.toString()}
                    data={backingPlatformData}
                    label="Backing Platform"
                    onChange={(e) => {
                        if (e !== null) {
                            const targetBackingPlatform = parseInt(e);
                            setBackingPlatform(targetBackingPlatform);

                            if(targetBackingPlatform===EPledgeBackingPlatform.SHOPIFY) {
                                setShopifyData({
                                    pledge_id: 0,
                                    order_id: 0,
                                    date: new Date()
                                })
                            }
                            else{
                                setShopifyData(null);
                            }
                        }
                    }}
                />
                <Select
                    searchable
                    data={selectData}
                    label="Reward"
                    value={selectValue}
                    onChange={(e) => {
                        setSelectValue(e);
                    }}
                />
                <NumberInput
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => {
                        if (!!e) setQuantity(e);
                    }}
                />
                <Checkbox
                    checked={imported}
                    onChange={(e) => {
                        setImported(e.target.checked);
                    }}
                    label="Imported"
                />

                {backingPlatform===EPledgeBackingPlatform.SHOPIFY && !!shopifyData && (
                    <ShopifyDataView shopifyData={shopifyData} setShopifyData={setShopifyData}/>
                )}

                {rest.email === null && (
                    <Alert variant="light" color="orange" title="Important" icon={<IconInfoCircle />}>
                        At least one pledge is required for the backer to appear in the list
                    </Alert>
                )}
                <Button
                    disabled={email === '' || backerEmail === '' || selectValue === '' || selectValue === null}
                    onClick={createHandler}
                >
                    {titleText}
                </Button>
            </Stack>
            <LoadingOverlay visible={loading} />
        </Modal>
    );
}

export function ShopifyDataView({shopifyData, setShopifyData} : {shopifyData: PledgeShopifyData, setShopifyData: (data: PledgeShopifyData) => void}) {
    return (
        <Stack bg={'#dfdfdf'} p={10} spacing={5} pt={10} pb={10} sx={{ borderRadius: 10 }}>
            
            <NumberInput label="Shopify Order ID" value={shopifyData?.order_id??0} onChange={(e) => {
                setShopifyData({
                    ...shopifyData,
                    order_id: e===""?0:e
                })
            }}/>
            <DateInput label="Shopify Order Date" value={shopifyData?.date??new Date()} onChange={(e) => {
                 setShopifyData({
                    ...shopifyData,
                    date: e??new Date()
                })
            }}/>
        </Stack>)
}
