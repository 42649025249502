import { Grid } from '@mantine/core';
import PledgeListComponent from './pledge-list/pledge-customer-list.component';
import { useEffect, useState } from 'react';
import Nullable from '@/dataTypes/Nullable';
import { useMount } from 'ahooks';
import { useInjection } from 'inversify-react';
import IUIService from '@/services/IUIService';
import ITreasuryService from '@/services/ITreasuryService';
import { useDebouncedState } from '@mantine/hooks';
import CustomerList from '@/models/treasury/pledges/CustomerList';
import PledgeCPForm from './pledge-cp-form/pledge-cp-form.component';
import Pledge, { EPledgeBackingPlatform, PledgeShopifyData } from '../../models/treasury/pledges/Pledge';
import PledgeEditForm from './pledge-edit-form/pledge-edit-form.component';
import OrderHistoryWithName from '../../models/treasury/orderHistory/OrderHistoryWithName';
import PledgeAddBacker from './pledge-add-backer/pledge-add-backer.component';
import EmailHistoryItem from '@/models/treasury/emailHistory/EmailHistoryItem';
import PledgeAddEmail from './email-add-association/pledge-email-add-association.component';
import PledgeViewDetails from './pledge-view-details-form.tsx/pledge-view-details-form.component';

export default function PledgeCPDirectory() {
    const uiService = useInjection<IUIService>('IUIService');
    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');
    const [customerList, setCustomerList] = useState<Nullable<CustomerList>>(null);
    const [page, setPage] = useState<number>(1);
    const [selectedEmail, setSelectedEmail] = useState<Nullable<string>>();
    const [search, setSearch] = useDebouncedState<string>('', 400);
    const [createVisible, setCreateVisible] = useState(false);
    const [addBackerVisible, setAddBackerVisible] = useState(false);
    const [pledges, setPledges] = useState<Pledge[]>([]);
    const [orderHistory, setOrderHistory] = useState<OrderHistoryWithName[]>([]);
    const [editingPledge, setEditingPledge] = useState<Nullable<Pledge>>(null);
    const [viewablePledge, setViewablePledge] = useState<Nullable<Pledge>>(null);
    const [emails, setEmails] = useState<EmailHistoryItem[]>([]);
    const [addEmailVisible, setAddEmailVisible] = useState(false);

    useMount(() => {
        getItems();
    });

    useEffect(() => {
        getItems();
    }, [page, search]);

    const getCustomerPledges = (getOrderHistory?: boolean) => {
        uiService.showLoading();
        treasuryService.getCustomerPledges(selectedEmail!).then((pledges) => {
            uiService.hideLoading();
            if (!!pledges) {
                setPledges(pledges);

                if (!!getOrderHistory) {
                    getCustomerOrderHistory();
                }

                treasuryService.getEmailHistory(selectedEmail!).then((emails) => {
                    setEmails(emails);
                });
            } else {
                uiService.showErrorNotification('Failed to load customer pledges');
            }
        });
    };

    const getCustomerOrderHistory = () => {
        uiService.showLoading();
        treasuryService.getOrderHistory(selectedEmail!).then((orders) => {
            uiService.hideLoading();
            if (!!orders) {
                setOrderHistory(orders);
            } else {
                uiService.showErrorNotification('Failed to load customer order history');
            }
        });
    };

    useEffect(() => {
        getCustomerPledges(true);
    }, [selectedEmail]);

    const getItems = () => {
        uiService.showLoading();
        treasuryService.getCustomers(page, search).then((customers) => {
            uiService.hideLoading();
            if (!!customers) {
                setCustomerList(customers);
                if (customers.getItems().length > 0) {
                    setSelectedEmail(customers.getItems()[0]);
                }
            } else {
                uiService.showErrorNotification('Failed to load customer list');
            }
        });
    };

    const renderList = () => {
        return (
            <PledgeListComponent
                customerList={customerList}
                page={page}
                searchChange={(s) => setSearch(s)}
                onPageChange={(e) => setPage(e)}
                canCreate={true}
                selectedEmail={selectedEmail!}
                selectionChange={(newId) => {
                    setSelectedEmail(newId);
                }}
                create={() => {
                    setCreateVisible(true);
                }}
                onAddNewBacker={() => {
                    setAddBackerVisible(true);
                }}
            />
        );
    };

    const addPledge = async (rewardId: number, email: string, backerEmail: string, backingPlatform: EPledgeBackingPlatform, quantity: number, imported: boolean, shopifyData : Nullable<PledgeShopifyData>) => {
        uiService.showLoading();
        const response = await treasuryService.createPledge({
            wod_email: email,
            backer_email : backerEmail,
            quantity: quantity,
            reward_id: rewardId,
            available_quantity: quantity,
            claimed_quantity: 0,
            pledge_id: 0,
            imported: imported,
            backing_platform : backingPlatform,
            shopify_data : shopifyData,
        });
        uiService.hideLoading();

        if (!response) {
            uiService.showErrorNotification('Failed to create a new pledge!');
        }
    }

    return (
        <>
            <Grid w="calc(100vw - 250px)">
                <Grid.Col span="content">{renderList()}</Grid.Col>
                <Grid.Col span="auto">
                    {selectedEmail === null ? (
                        'No Data to Display'
                    ) : (
                        <PledgeCPForm
                            orderHistories={orderHistory}
                            selectedEmail={selectedEmail!}
                            pledges={pledges}
                            emailHistory={emails}
                            onClickAddEmail={() => {
                                setAddEmailVisible(true);
                            }}
                            editPledge={(pledge) => {
                                setEditingPledge(pledge);
                            }}
                            viewPledge ={(pledge) => {
                                console.log(pledge);
                                setViewablePledge(pledge);
                            }}
                            deletePledge={async (id) => {
                                uiService.showLoading();
                                const response = await treasuryService.deletePledge(id);
                                uiService.hideLoading();

                                if (!response) {
                                    uiService.showErrorNotification('Failed to remove the pledge');
                                } else {
                                    getCustomerPledges();
                                }
                            }}
                        />
                    )}
                </Grid.Col>
            </Grid>

            <PledgeAddBacker
                email={null}
                closed={() => {
                    setAddBackerVisible(false);
                }}
                visible={addBackerVisible}
                create={async (r, email, backerEmail, backingPlatform, quantity, imported, shopifyData) => {
                    addPledge(r, email, backerEmail, backingPlatform, quantity, imported, shopifyData);
                    
                    setAddBackerVisible(false);
                    getItems();
                }}
            />

            <PledgeAddBacker
                email={selectedEmail ?? ''}
                create={async (r, email, backerEmail, backingPlatform, quantity, imported, shopifyData) => {
                    addPledge(r, email, backerEmail, backingPlatform, quantity, imported, shopifyData);

                    setCreateVisible(false);
                    getCustomerPledges();
                }}
                visible={createVisible}
                closed={() => {
                    setCreateVisible(false);
                }}
            />

            <PledgeEditForm
                visible={!!editingPledge}
                pledge={editingPledge!}
                closed={() => {
                    setEditingPledge(null);
                }}
                edit={async (pledge, backerEmail, backingPlatform, claimedQuantity, quantity, imported, shopifyData) => {
                    uiService.showLoading();
                    const newPledge : Pledge = {
                        ...pledge,
                        backing_platform : backingPlatform,
                        backer_email: backerEmail,
                        claimed_quantity: claimedQuantity,
                        quantity: quantity,
                        imported: imported,
                        shopify_data : !shopifyData ?null: shopifyData,
                    };
                    const response = await treasuryService.savePledge(newPledge);
                    uiService.hideLoading();

                    if (!!response) {
                        getCustomerPledges();
                    } else {
                        uiService.showErrorNotification('Failed to save the pledge');
                    }

                    setEditingPledge(null);
                }}
            />

            <PledgeAddEmail
                visible={!!addEmailVisible}
                closed={() => {
                    setAddEmailVisible(false);
                }}
                create={async (ogEmail, targetEmail) => {
                    if(!ogEmail || !targetEmail) {
                        setAddEmailVisible(false);
                        uiService.showErrorNotification('Email  is missing');
                        return;
                    }

                    if(ogEmail.trim()==='' || targetEmail.trim()==='') {
                        setAddEmailVisible(false);
                        uiService.showErrorNotification('Email  is missing');
                        return;
                    }

                    uiService.showLoading();

                    const response = await treasuryService.updateEmail(ogEmail, targetEmail);

                    if(!!response){
                        setAddEmailVisible(false);
                        uiService.showSuccessNotification('Email updated');
                        getCustomerPledges(true);
                    }
                    else{ 
                        setAddEmailVisible(false);
                        uiService.showErrorNotification('Failed to update email');
                    }
                }}
                email={selectedEmail ?? ''}
            />

            <PledgeViewDetails
                visible={viewablePledge !== null}
                closed={() => {
                    setViewablePledge(null);
                }}
                pledge={viewablePledge!}
            />
        </>
    );
}
